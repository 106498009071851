import * as API from "./base";

const url = "/users/password";

export const update = ({ token, password }) =>
  API.put(
    url,
    {
      user: {
        reset_password_token: token,
        password_confirmation: password,
        password: password
      }
    },
    { skipUrlNormalization: true }
  ).then((sess) => {
    if (sess && sess.token) {
      API.setToken(sess.token);
    }
    return sess;
  });

export const reset = ({ email }) =>
  API.post(url, { user: { email } }, { skipUrlNormalization: true });

export const verify = ({ token }) => API.get(`/users/passwords/check/${token}`);
