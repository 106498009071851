/* *****************************************
 * Database mock - essentially a MySQL DB
 * ****************************************/
let database;
let autoincrementPointers;

// The initial state of the db with tables
const initialDb = (data) => ({
  // these next two are the same, we'll normalize later
  avatars: [],
  comments: [],
  event_locations: [],
  line_items: [],
  projects: [],
  products: [],
  project_memberships: [],
  orders: [],
  postal_codes: [],
  professionals: [],
  recommendations: [],
  users: [],
  services: [],
  templates: [],
  time_slots: [],
  feed: [],
  suggestedProjects: [],
  suggestedFriends: [],
  ...(data || {})
});

// For every table in the db, create an autoincrement table map
const initialAutoincrementPointers = (db) =>
  Object.entries(db).reduce((ac, i) => ({ ...ac, [i[0]]: 1 }), {});

const initializeDatabase = (data) =>
  (autoincrementPointers = initialAutoincrementPointers(
    (database = initialDb(data))
  ));

initializeDatabase();

const nextId = (model) => autoincrementPointers[model]++;

// data accessors
const tap = (value, callback) => [callback(value), value][1];

const fieldMatch = (condition) => (row) =>
  `${row[condition[0]]}` === `${condition[1]}`;

const fieldsMatch = (conditions) => (row) =>
  Object.entries(conditions).every((condition) => fieldMatch(condition)(row));

export const table = (name) => database[name] || [];

export const find = (tableName, field, value) =>
  table(tableName).find(fieldMatch([field, value]));

export const findBy = (tableName, conditions) =>
  table(tableName).find(fieldsMatch(conditions));

// Cleanup or init with dataset
export const mock = (data) => initializeDatabase(data);

export const reset = mock;

// DB Crud methods
export const create = (model, params) =>
  tap({ ...params, id: nextId(model) }, (record) => table(model).push(record));

export const destroy = (model, id) =>
  table(model).splice(
    table(model)
      .map((i) => i.id)
      .indexOf(id)
  );

export const update = (model, id, params) =>
  Object.assign(find(model, { id }), params);

export const show = (model, id) => findBy(model, { id });

export const index = (model) => table(model);
