/* Fetch doesn't treat a failure as throwable error. We do */
export const raiseFailedRequest = (response) =>
  response.ok ? response : Promise.reject(response);

/* Handle JSON response, or weird strings if we're in tests */
export const coerceResponse = (response) =>
  response.json ?
    response.json() :
    typeof response == "string" ?
    JSON.parse(response) :
    response;

// Same as coerceResponse, except with a promise
export const coerceError = (response) =>
  Promise.reject(coerceResponse(response));
