import * as API from "./base";

const exports = API.crudify("user", "/api/users", (handlers) => ({
  create: (user) =>
    API.post("/users", { user }).then((sess) => {
      if (sess && sess.token) {
        API.setToken(sess.token);
      }
      return sess;
    }),

  creditBalance: (id) => API.get(`/api/users/${id}/credit_balance`)
}));

export const create = exports.create;
export const destroy = exports.destroy;
export const index = exports.index;
export const show = exports.show;
export const update = exports.update;

export const creditBalance = exports.creditBalance;
