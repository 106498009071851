export const composeParams = (params) => {
  if (params && Object.keys(params).length) {
    return (
      "?" +
      Object.entries(params)
        .map(([key, value]) => key + "=" + encodeURIComponent(value))
        .join("&")
    );
  } else {
    return "";
  }
};

export const appendParameters = (data, method) => (url) =>
  method == "GET" ? url + composeParams(data) : url;

const _isFile = (file) => file && file instanceof File;

const isFile = (file) => file && (_isFile(file.rawFile) || _isFile(file));

// Only check one-level deep for now
// let i = 0
export const hasFiles = (data) => {
  if (isFile(data)) {
    // console.log("IS File", data)
    return true;
  } else if (Array.isArray(data)) {
    // console.log("Is Array", data)
    return data.some(isFile);
  } else if (typeof data === "string") {
    // console.log("Is String", data)
    return isFile(data);
  } else if (data === Object(data)) {
    // console.log("Is Object", data)
    return Object.values(data).some(hasFiles);
  } else {
    // console.log("Random", data)
    return isFile(data);
  }
};

// FormData Call (for multipart)
const multiPartData = (data) => {
  const formData = new FormData();

  const parentKey = Object.keys(data)[0];

  Object.entries(data[parentKey]).forEach((kv) => {
    const formKey = parentKey + "[" + kv[0] + "]";
    const formValue = isFile(kv[1]) ? kv[1].rawFile || kv[1] : kv[1];

    if (!isFile(formValue) && formValue === Object(formValue)) {
      Object.entries(formValue).forEach((kv2) => {
        const deepFormKey = formKey + "[" + kv2[0] + "]";
        const deepFormValue = kv2[1];

        formData.append(deepFormKey, deepFormValue);
      });
    } else {
      formData.append(formKey, formValue);
    }
  });

  return formData;
};

const formDataData = (data) => JSON.stringify(data || {});

const _appendFiles = (options, data, containsFiles) => ({
  ...options,
  body: containsFiles ? multiPartData(data) : formDataData(data),
  headers: containsFiles ?
    {} :
    { ...(options.headers || {}), "Content-Type": "application/json" }
});

export const appendFiles = (data, method) => (options) =>
  method == "GET" ? options : _appendFiles(options, data, hasFiles(data));
