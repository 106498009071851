// From: https://robwise.github.io/blog/testing-optimistic-updating-ui-with-capybara
// To allow tracking all fetch calls, for use in capybara
const pendingAjaxRequestUuids = new Set();

const updateBodyAttribute = () => ""; // (typeof document !== 'undefined') && document.body.setAttribute('data-pending_ajax_requests', !(pendingAjaxRequestUuids.size == 0))

const generateRequestId = () => "_" + Math.random().toString(36).substr(2, 9);

const startRequest = (requestId) => {
  pendingAjaxRequestUuids.add(requestId);
  updateBodyAttribute();
};

const endRequest = (requestId) => {
  pendingAjaxRequestUuids.delete(requestId);
  updateBodyAttribute();
};

// This is a hook for E2E tests to wait for requests

const complete = (requestId) => (result) =>
  [endRequest(requestId), result].pop();

const track =
  (fetcher) =>
  (...args) => {
    let requestId = generateRequestId();

    startRequest(requestId);

    return fetcher(...args)
      .then(complete(requestId))
      .catch(complete(requestId));
  };

export default track;
