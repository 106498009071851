import * as API from "./base";

export const create = (user) =>
  API.post("/users/sign_in", { user }).then((sess) => {
    if (sess && sess.token) {
      API.setToken(sess.token);
    }
    return sess;
  });

export const destroy = () =>
  API.apiDelete("/users/sign_out").then(() => API.unsetToken());

export const initialize = (params) => API.get("/api/authenticated", params);

export const omniauth = (provider, payload) =>
  API.get("/users/auth/" + provider + "/callback", payload, {
    skipUrlNormalization: true
  }).then((user) => {
    if (user && user.token) {
      API.setToken(user.token);
    }
    return user;
  });
