import API, { Base } from "../index.js";
export * from "./database";
import * as Database from "./database";

const finderTest = (obj, key, value) =>
  key == "since" ? `${obj.created_at}` >= `${value}` : `${obj[key]}` === `${value}`;

const validatesFormatOf = (field, matchRegex) => (row) =>
  row[field] && row[field].match(matchRegex);

const finder = (obj) => (criteria) =>
  Array.isArray(criteria[1]) ?
    criteria[1].some((value) => finderTest(obj, criteria[0], value)) :
    finderTest(obj, criteria[0], criteria[1]);

// Finder helpers
const findAllBy = (list, expectations) =>
  (list || []).filter((obj) =>
    Object.entries(expectations || {}).every(finder(obj))
  );

// Responders
const respond = (errors) => (callback) =>
  errors ? Promise.reject(errors) : Promise.resolve(callback());

const createHandler = (model, params) => {
  let { errors, ...createParams } = params;
  return respond(errors)(() => Database.create(model, createParams));
};

const updateHandler = (model, id, params) => {
  let { errors, ...updateParams } = params;
  return respond(errors)(() => Database.update(model, id, updateParams));
};

const showHandler = (model, id, errors) =>
  respond(errors)(() => Database.show(model, id));

const indexHandler = (model, params) =>
  Promise.resolve(
    params && params.filter ?
      findAllBy(Database.index(model), JSON.parse(params.filter)) :
      Database.index(model)
  );

const respondWithErr = (result, err) =>
  result ?
    Promise.resolve(result) :
    Promise.reject(typeof err === "string" ? new Error(err) : err);

// Most of our models use simple Restful Crud, but, some legacy APIs
// require slight modifications
const crudHandlers = (model, customizer) => {
  let handlers = {
    create: (params) => createHandler(model, params),
    update: (id, params) => updateHandler(model, id, params),
    show: (id) => showHandler(model, id),
    index: (params) => indexHandler(model, params)
  };

  return customizer ? { ...handlers, ...customizer(handlers) } : handlers;
};

const handleCart = (m, items) =>
  items &&
  items
    .filter(
      (item) =>
        (item.id && item.quantity && item.quantity > 0) ||
        (["tax", "fee", "tip"].includes(item.key) &&
          item.price &&
          item.price > 0)
    )
    .forEach((item) => {
      // console.log("Setting line items", item)
      const { id, ...rest } = item;

      const processed = {
        ...rest,
        sku: item.key,
        product_id: id,
        project_membership_id: m.id,
        quantity: item.quantity || 1
      };

      // console.log("Processed", processed)
      return Database.create("line_items", processed);
    });

export const mockBackend = (data) => {
  Database.mock(data);

  API.project = crudHandlers("projects", (handlers) => ({
    index: (fullParams) => {

      const {
        filter,
        ...params
      } = fullParams || {}

      const {
        run_at_since,
        run_at_within,
        run_on,
        ...filterParams
      } = filter ? JSON.parse(filter) : {}

      const specialFilters = {}

      if(run_at_since) {
        specialFilters.run_at_since = run_at_since;
      }

      if(run_at_within) {
        specialFilters.run_at_within = run_at_within;
      }

      if(run_on) {
        specialFilters.run_on = run_on;
      }

      if(Object.entries(filterParams)) {
        params.filter = JSON.stringify(filterParams)
      }

      return handlers
        .index(params)
        .then((records) =>
          records.filter((record) => {
            if(Object.keys(specialFilters).length == 0) {
              return true
            }

            return Object
              .entries(specialFilters)
              .every(filt => {
                switch(filt[0]) {
                  case "run_at_since":
                    return record.run_at >= filt[1];
                  case "run_on":
                    const sameDay = (a, b) => ["getFullYear", "getMonth", "getDate"].every(func => a[func]() === b[func]());
                    return sameDay(new Date(record.run_at), new Date(`${filt[1]}T12:00:00.000Z`));
                  case "run_at_within":
                    const starts = filt[1][0];
                    const ends = filt[1][1];
                    return record.run_at >= starts && record.run_at <= ends
                  default:
                    return true
                }
              })

            })
          )
    }
  }));

  API.order = crudHandlers("orders", (handlers) => ({
    create: (params) => API.membership.create(params)
  }));

  API.membership = crudHandlers("project_memberships", (handlers) => ({
    create: (params) => {
      const projectId = params.project_id;
      const user = Database.find("users", "authed", true);
      const items = params.items;

      if (user) {
        const membership = Database.findBy("project_memberships", {
          user_id: user.id,
          project_id: projectId
        });

        const project =
          Database.find("projects", "id", projectId) ||
          Database.create("projects", { id: projectId });

        Database.update("projects", projectId, {
          ...project,
          active_member_ids: (project.active_member_ids || []).concat([
            user.id
          ]),
          member_ids: (project.member_ids || []).concat([user.id]),
          joined: true
        });

        Database.destroy("suggestedProjects", projectId);

        Database.update("users", user.id, {
          ...user,
          active_membership_project_ids: (
            user.active_membership_project_ids || []
          ).concat([projectId]),
          inactive_membership_project_ids: (
            user.inactive_membership_project_ids || []
          ).filter((id) => `${id}` != `${projectId}`),
          project_ids: (user.project_ids || []).concat([projectId])
        });

        const mem = membership ?
          handlers.update(membership.id, { ...params, status: "active" }) :
          handlers.create({ ...params, user_id: user.id, status: "active" });
        return Promise.resolve(mem)
          .then((mem) => handleCart(mem, items))
          .then(() => mem);
      } else {
        return Promise.reject(new Error("no auth"));
      }
    },
    destroy: (id) => {
      const user = Database.find("users", "authed", true);
      const projectMembership = Database.find("project_memberships", "id", id);

      const projectId = projectMembership.project_id;

      const sp = Database.table("suggestedProjects");

      if (sp.indexOf(projectId) === -1) {
        sp.push(projectId);
      }

      if (user) {
        const project =
          Database.find("projects", "id", projectId) ||
          Database.create("projects", { id: projectId });

        Database.update("projects", projectId, {
          ...project,
          active_member_ids: (project.active_member_ids || []).filter(
            (id) => `${id}` != `${user.id}`
          ),

          member_ids: [
            ...new Set((project.member_ids || []).concat([user.id]))
          ],
          joined: false
        });

        Database.update("users", user.id, {
          ...user,
          active_membership_project_ids: (
            user.active_membership_project_ids || []
          ).filter((id) => `${id}` != `${projectId}`),
          inactive_membership_project_ids: (
            user.inactive_membership_project_ids || []
          ).concat([projectId]),
          project_ids: (user.project_ids || []).filter(
            (id) => `${id}` != `${projectId}`
          )
        });

        return handlers.update(id, {
          ...projectMembership,
          status: "inactive"
        });
        // return Promise.resolve(Database.find('projects', 'id', projectId))
      } else {
        return Promise.reject(new Error("no auth"));
      }
    }
  }));

  API.discussion = crudHandlers("comments");

  API.user = crudHandlers("users", (handlers) => ({
    creditBalance: (id) => {
      const user = Database.find("users", "authed", true);

      return Promise.resolve({
        credit_balance: user && user.credit_balance ? user.credit_balance : 0
      });
    },

    create: (params) => {
      const { ...rest } = params;

      const existingUser = Database.find("users", "email", params.email);
      const existingAuth = Database.findBy("users", {
        email: params.email,
        password: params.password
      });

      if (!params.password) {
        return respondWithErr(false, "Registration requires password");
      }

      if (existingAuth) {
        return handlers.update(rest.id, { authed: true });
      }

      if (params && params.postal_code && params.postal_code.match(/^9.*/)) {
        return respondWithErr(false, {
          errors: { out_of_area: ["Way out of area"] }
        });
      }

      if (!params.email || existingUser) {
        return respondWithErr(false, {
          errors: { existing_email: ["Email blank or pre-existing"] }
        });
      }

      return handlers.create({ ...rest, authed: true }); // automatically log this user in for tests
    },
    update: (id, params) => {
      const { ...rest } = params;
      const rec = Database.table("users")[0];
      return handlers.update(rec.id, { ...rest });
    } // eslint-disable-line no-undef
  }));

  API.lineItem = crudHandlers("line_items");
  API.menuCategory = crudHandlers("menu_categories");

  API.service = crudHandlers("services");
  API.template = crudHandlers("templates");
  API.professional = crudHandlers("professionals");
  API.recommendation = crudHandlers("recommendations", (handlers) => ({
    create: (params) => {
      // User id is inferred
      const user_id = Database.find("users", "authed", true).id;

      const proFields = [
        "name",
        "business_name",
        "google_place_id",
        "phone",
        "email",
        "website",
        "bio"
      ];

      const proValues = params.contractor_attrs ?
        params.contractor_attrs :
        Object.entries(params)
            .filter((pair) => proFields.includes(pair[0]))
            .reduce((ac, pair) => ({ ...ac, [pair[0]]: pair[1] }), {});

      // Backend actually creates pro from parameters
      const contractor_id_promise =
        Object.entries(proValues).length > 0 ?
          createHandler("professionals", proValues).then((pro) => pro.id) :
          Promise.resolve(params.contractor_id);

      return contractor_id_promise.then((contractor_id) =>
        handlers.create({
          contractor_id,
          user_id
        })
      );
    }
  }));

  API.feed = crudHandlers("feed");

  // API.product = crudHandlers('products')
  API.product = crudHandlers("products", (handlers) => ({
    index: (params) => {
      const project_id =
        params && params.filter && JSON.parse(params.filter).project_id;

      return project_id ?
        Promise.resolve(
            Database.table("products").filter((p) =>
              p.project_ids.some((pid) => `${pid}` === `${project_id}`)
            )
          ) :
        handlers.index(params);
    }
  }));

  API.relevance = crudHandlers("relevance", (handlers) => ({
    index: (type, params) =>
      Promise.resolve(
        Database.table(
          "suggested" + (type.charAt(0).toUpperCase() + type.slice(1))
        )
      ), // eslint-disable-line no-undef
    projects: (params) => API.relevance.index("projects", params), // eslint-disable-line no-undef
    friends: (params) => API.relevance.index("friends", params) // eslint-disable-line no-undef
  }));

  API.session = {
    initialize: (params) =>
      respondWithErr(
        params && params.error ?
          null :
          params || Database.find("users", "authed", true),
        "no auth"
      ),

    create: ({ email, password }) => {
      const authedUser = Database.findBy("users", { email, password });

      if (authedUser) {
        Base.setToken("token");
        Object.assign(authedUser, { authed: true, token: "token" });
        return respondWithErr(authedUser);
      }

      const missingUser = !Database.findBy("users", { email });

      return respondWithErr(
        false,
        missingUser ? { error: "no existing" } : "No Session"
      );
    },

    destroy: () => {
      const user = Database.findBy("users", { authed: true });

      Base.unsetToken();
      return respondWithErr(user, "no authed").then((u) =>
        Object.assign(u, { authed: false })
      );
    }
  };

  // const extractZip = address => {
  //   if(!address) {
  //     return address
  //   }
  //
  //   const matched = address.match(/(\d{5})/g)
  //
  //   return matched[0]
  // }

  API.home = {
    // No returned data, jjust pass/fail based on format
    create: jest.fn((postal_code, userId) => { // eslint-disable-line
      // eslint-disable-line
      const validatedAddress = validatesFormatOf(
        "postal_code",
        /6[0-9]{4}/
      )({ postal_code });

      if (!validatedAddress) {
        return Promise.reject(false);
      }

      const user = Database.findBy("users", { authed: true });

      if (user) {
        user.postal_code = postal_code;

        Database.update("users", user.id, {
          postal_code
        });
      }

      return Promise.resolve(postal_code);
    })
  };

  API.password = {
    update: ({ token, password }) =>
      respondWithErr(
        token == "invalid" ?
          null :
          {
              reset_password_token: token,
              password_confirmation: password,
              password
            },
        "invalid token for update"
      ),
    verify: ({ token }) =>
      respondWithErr(token == "invalid" ? null : { token }, "invalid token"),
    reset: (params) =>
      respondWithErr({ ...params, message: "Reset Sent to " + params.email })
  };

  API.paymentIntent = {
    create: jest.fn((payment) => // eslint-disable-line
      Promise.resolve({ ...payment, client_secret: "dummy_client_secret" })
    ), // eslint-disable-line
  };

  API.timeSlot = crudHandlers("time_slots");

  API.eventLocation = crudHandlers("event_locations");

  API.postalCode = crudHandlers("postal_codes");

  API.avatar = crudHandlers("avatars", (handlers) => ({
    show: (id) => {
      const list = id.split(/_/);
      return respondWithErr(
        Database.findBy("avatars", { type: list[0], id: list[1] }),
        "No Avatar"
      ).then((obj) => ({ avatar: obj.src }));
    }
  }));

  API.confirm = {
    resend: (params) =>
      respondWithErr({ ...params, message: "Resend Sent to " + params.email })
  };

  API.subscription = {
    create: (subscriber) => respondWithErr({ subscriber })
  };
}; // errors
