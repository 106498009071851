import * as API from "./base";

export const index = (friends) =>
  friends ?
    API.get("/api/recommendations/network") :
    API.get("/api/recommendations/index");

export const create = (recommendation) =>
  API.post("/api/recommendations", { recommendation });

export const destroy = (id) =>
  API.apiDelete("/api/recommendations/destroy", { id });

export const show = (id) => API.get("/api/recommendations/" + id);
