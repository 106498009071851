import track from "./track";

import { prependHost, appendToken } from "./endpoint";

import { appendFormat } from "./format";

import { appendFiles, appendParameters } from "./parameters";

import { coerceError, coerceResponse, raiseFailedRequest } from "./response";

export * from "./crudify";

export * from "./endpoint";

export * from "./verbs";

// Initial set of global fetch options
const basicHeaders = (method, token = null) => ({
  method,
  credentials: "include",
  headers: {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    "Content-Type": "application/json"
  }
});

// Let us chain url/option transformations
const applyTransform = (a, f) => f(a);

export const transformUrl = ({ callOptions, data, method, callUrl }) =>
  [
    prependHost(),
    appendFormat(callOptions),
    appendParameters(data, method)
  ].reduce(applyTransform, callUrl);

export const transformOptions = ({ data, method }) =>
  [appendFiles(data, method)].reduce(
    applyTransform,
    basicHeaders(method, appendToken())
  );

// non-multipart Call
export const call = (callUrl, data, method, callOptions = {}) => {
  const url = transformUrl({ callOptions, data, method, callUrl });

  const options = transformOptions({ data, method });

  const fetcher = track(fetch);

  return fetcher(url, options)
    .then(raiseFailedRequest)
    .then(coerceResponse)
    .catch(coerceError);
};

// Generic logging for api calls
export const logError = (title, defaultValue) => (err) => {
  // eslint-disable-line no-console
  console.log(title, err);
  return defaultValue;
};
