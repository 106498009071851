import * as Verbs from "./verbs";

// Crud Helpers
export const crudify = (model, baseEndpoint, customizer) => {
  let handlers = {
    create: (obj) => Verbs.post(baseEndpoint, { [model]: obj }),
    update: (id, obj) => Verbs.put(`${baseEndpoint}/${id}`, { [model]: obj }),
    index: (params) => Verbs.get(baseEndpoint, params),
    show: (id) => Verbs.get(`${baseEndpoint}/${id}`),
    destroy: (id) => Verbs.apiDelete(`${baseEndpoint}/${id}`)
  };

  return customizer ? { ...handlers, ...customizer(handlers) } : handlers;
};
