import { call } from "./index";

export const get = (url, data, options) => call(url, data, "GET", options);

export const post = (url, data, options) => call(url, data, "POST", options);

export const put = (url, data, options) => call(url, data, "PUT", options);

export const apiDelete = (url, data, options) =>
  call(url, data, "DELETE", options);
