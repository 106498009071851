let baseHost;
let authToken;

export const setHost = (host) => (baseHost = host);

export const unsetHost = () => (baseHost = undefined);

const _prependHost = (host, url) => host + url;

export const prependHost =
  (host = baseHost) =>
  (url) =>
    baseHost ? _prependHost(baseHost, url) : url;

export const setToken = (token) => token && token.length && (authToken = token);

export const unsetToken = () => (authToken = undefined);

export const appendToken = (token = authToken) => token;
