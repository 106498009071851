import * as Base from "./api/base/index.js";

import * as Avatar from "./api/avatar.js";
import * as Confirm from "./api/confirm.js";
import * as Discussion from "./api/discussion.js";
import * as EventLocation from "./api/eventLocation.js";
import * as FacebookFriend from "./api/facebookFriend.js";
import * as Feed from "./api/feed.js";
import * as Home from "./api/home.js";
import * as Invitation from "./api/invitation.js";
import * as LineItem from "./api/lineItem.js";
import * as Membership from "./api/membership.js";
import * as MenuCategory from "./api/menuCategory.js";
import * as Order from "./api/order.js";
import * as Password from "./api/password.js";
import * as PaymentIntent from "./api/paymentIntent.js";
import * as PostalCode from "./api/postalCode.js";
import * as Product from "./api/product.js";
import * as Professional from "./api/professional.js";
import * as Project from "./api/project.js";
import * as Recommendation from "./api/recommendation.js";
import * as RecommendationRequest from "./api/recommendationRequest.js";
import * as RecommendationSubmission from "./api/recommendationSubmission.js";
import * as Relevance from "./api/relevance.js";
import * as Service from "./api/service.js";
import * as Session from "./api/session.js";
import * as Subscription from "./api/subscription.js";
import * as Template from "./api/template.js";
import * as TimeSlot from "./api/timeSlot.js";
import * as User from "./api/user.js";

import * as Mocks from "./mocks/index.js";

const API = {
  // eslint-disable-line no-unused-vars
  avatar: Avatar,
  confirm: Confirm,
  discussion: Discussion,
  eventLocation: EventLocation,
  facebookFriend: FacebookFriend,
  feed: Feed,
  home: Home,
  invitation: Invitation,
  lineItem: LineItem,
  membership: Membership,
  menuCategory: MenuCategory,
  order: Order,
  password: Password,
  paymentIntent: PaymentIntent,
  postalCode: PostalCode,
  professional: Professional,
  product: Product,
  project: Project,
  recommendation: Recommendation,
  recommendationRequest: RecommendationRequest,
  recommendationSubmission: RecommendationSubmission,
  relevance: Relevance,
  service: Service,
  session: Session,
  subscription: Subscription,
  template: Template,
  timeSlot: TimeSlot,
  user: User
};

export { Base, Mocks };

export default API;
