import * as API from "./base";

export const create = (emails, userId, projectId) =>
  API.post(
    "/users/" + userId + "/invites/batch",
    Object.assign(
      { invites: { emails: emails.join(",") } },
      projectId ? { project_id: projectId } : {}
    )
  );
